<template>
  <div
    class="tw-border tw-border-blue-350 tw-justify-start tw-p-4 tw-flex tw-items-center"
  >
    <div
      class="tw-h-full tw-flex tw-flex-col tw-justify-between tw-text-base tw-text-gray-800"
    >
      <div class="tw-font-medium tw-text-gray-555 tw-flex">
        <span>
          {{ title }}
          <template v-if="allowances.hasRemainingAllowanceRange">
            Range
          </template>
        </span>

        <div class="tw-flex tw-items-center">
          <slot name="extended-title"></slot>
        </div>
      </div>

      <div class="tw-mt-2" data-cy="current-allowance-range">
        <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
          <template v-if="allowances.hasUnlimitedAllowances">∞</template>

          <template v-else>
            {{ minimumRemainingAllowance | toFraction }}
          </template>
        </span>

        <span v-if="allowances.hasRemainingAllowanceRange">
          <span> to</span>

          <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
            {{ maximumRemainingAllowance | toFraction }}
          </span>
        </span>

        <template v-if="!allowances.hasRemainingAllowanceRange">
          {{ capitalizedAllowanceUnit | pluralize(minimumRemainingAllowance) }}
        </template>

        <template v-else>
          {{ capitalizedAllowanceUnit | pluralize(maximumRemainingAllowance) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FormatNumbers from '@/mixins/FormatNumbers'
import { capitalize } from 'lodash-es'

export default {
  name: 'CurrentAllowance',

  mixins: [FormatNumbers],

  props: {
    allowances: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    minimumRemainingAllowance() {
      return this.toFixed(this.allowances.minimumRemainingAllowance, 2)
    },

    maximumRemainingAllowance() {
      return this.toFixed(this.allowances.maximumRemainingAllowance, 2)
    },

    capitalizedAllowanceUnit() {
      return capitalize(this.allowances.allowanceUnit)
    },
  },
}
</script>
