<template>
  <div
    class="allowance-stats tw-grid tw-grid-cols-3"
    :class="{
      'danger-current': yearlyAllowances.hasRemainingAllowancesExceeded,
    }"
  >
    <CurrentAllowance
      :allowances="filteredAllowances"
      :title="currentAllowanceTitle"
    >
      <template v-slot:extended-title>
        <span
          v-if="canShowAccruedAllowance"
          class="tw-bg-gray-350 tw-text-black tw-rounded-md tw-ml-3 tw-py-1 tw-px-2 tw--m-1"
          >{{ summaryView }}</span
        >

        <ExtraInfo
          v-if="canShowAccruedAllowance && isSummaryViewAccrued"
          class="tw-my-auto"
        >
          <div class="tw-p-4 tw-w-48">
            Accrued allowance is the portion of total yearly allowance earned up
            to the last date within this request.

            <p class="tw-mt-2">Yearly allowance</p>
            <p>
              {{ currentAllowanceTitle }}: {{ usedMinimumYearlyAllowances }}
              <template v-if="yearlyAllowances.hasRemainingAllowanceRange">
                to {{ usedMaximumYearlyAllowances }}
              </template>
              {{
                yearlyAllowances.allowanceUnit
                  | pluralize(usedMaximumYearlyAllowances)
              }}
            </p>
            <p>
              {{ newAllowanceTitle }}: {{ balanceMinimumYearlyAllowances }}
              <template v-if="yearlyAllowances.hasRemainingAllowanceRange">
                to {{ balanceMaximumYearlyAllowances }}
              </template>
              {{
                yearlyAllowances.allowanceUnit
                  | pluralize(balanceMaximumYearlyAllowances)
              }}
            </p>
          </div>
        </ExtraInfo>
      </template>
    </CurrentAllowance>

    <NewAllowance :allowances="filteredAllowances" :title="newAllowanceTitle">
      <template v-slot:extended-title>
        <span
          v-if="canShowAccruedAllowance"
          class="tw-bg-gray-350 tw-text-black tw-rounded-md tw-mx-3 tw-py-1 tw-px-2"
          >{{ summaryView }}</span
        >
      </template>
    </NewAllowance>

    <ChangedAllowance
      :allowances="yearlyAllowances"
      icon-classes="tw-bg-red-100 tw-text-red-700 tw-transform tw-rotate-90"
    />

    <Portal v-if="canShowAccruedAllowance" to="summary-view-switcher">
      <div class="tw-flex">
        <ToggleButton
          v-model="isShowAccrued"
          :labels="true"
          :sync="true"
          color="#1da1f2"
          @change="changeSummaryView"
        />
        <span class="tw-m-1">Show accrued</span>
      </div>
    </Portal>

    <Portal to="allowance-unlimited-alert">
      <BaseAlert
        v-if="isEmploymentAllowanceUnlimited"
        theme="info"
        class="tw-mt-2"
      >
        {{ employmentAllowanceUnlimitedText }}
      </BaseAlert>
    </Portal>
  </div>
</template>

<script>
import CurrentAllowance from '@/components/requests/CurrentAllowance'
import NewAllowance from '@/components/requests/NewAllowance'
import ChangedAllowance from '@/components/requests/ChangedAllowance'
import LeaveAllowances from '@/models/leave/LeaveAllowance'
import { ToggleButton } from 'vue-js-toggle-button'
import BaseAlert from '@/components/BaseAlert'
import EmploymentAllowanceUnlimitedText from '@/components/requests/EmploymentAllowanceUnlimitedText'
import FormatNumbers from '@/mixins/FormatNumbers'
import Settings from '@/api/employments/Settings'
import { mapActions } from 'vuex'
import { debounce } from 'lodash-es'
import LeavePermissions from '@/mixins/LeavePermissions'
const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'AllowanceDecrementMonitor',

  components: {
    BaseAlert,
    CurrentAllowance,
    NewAllowance,
    ChangedAllowance,
    ToggleButton,
    ExtraInfo,
  },

  mixins: [FormatNumbers, LeavePermissions],

  props: {
    allowances: {
      type: LeaveAllowances,
      required: true,
    },
  },

  data() {
    return {
      isShowAccrued: false,
    }
  },

  computed: {
    leave() {
      return this.allowances.leave[0]
    },

    status() {
      return this.leave.status
    },

    yearlyAllowances() {
      return this.allowances.getSummary()
    },

    accruedAllowances() {
      return this.allowances.getAccruedSummary()
    },

    summaryView() {
      return this.isShowAccrued ? 'Accrued' : 'Yearly'
    },

    isEmploymentAllowanceUnlimited() {
      return this.yearlyAllowances.items[0].hasUnlimitedAllowances
    },

    employmentAllowanceUnlimitedText() {
      return new EmploymentAllowanceUnlimitedText(
        this.activeEmployment,
        this.leave.owner
      ).getText()
    },

    currentAllowanceTitle() {
      const MAPPER = {
        APPROVED: 'Before',
      }

      return MAPPER[this.status?.toUpperCase()] ?? 'Current'
    },

    newAllowanceTitle() {
      const MAPPER = {
        APPROVED: 'After',
      }

      return MAPPER[this.status?.toUpperCase()] ?? 'New'
    },

    filteredAllowances() {
      return this.isShowAccrued ? this.accruedAllowances : this.yearlyAllowances
    },

    isSummaryViewAccrued() {
      return this.summaryView === 'Accrued'
    },

    usedMinimumYearlyAllowances() {
      return this.toFixed(this.yearlyAllowances.minimumRemainingAllowance, 2)
    },

    usedMaximumYearlyAllowances() {
      return this.toFixed(this.yearlyAllowances.maximumRemainingAllowance, 2)
    },

    balanceMinimumYearlyAllowances() {
      return this.toFixed(this.yearlyAllowances.minimumBalanceAllowance, 2)
    },

    balanceMaximumYearlyAllowances() {
      return this.toFixed(this.yearlyAllowances.maximumBalanceAllowance, 2)
    },

    canShowAccruedAllowance() {
      return (
        !this.isEmploymentAllowanceUnlimited &&
        this.activeCompany.allowanceAccrualEnabled &&
        this.hasPermissionToSeeAccrualAllowances
      )
    },

    hasPermissionToSeeAccrualAllowances() {
      return (
        this.activeEmployment.isAdmin() ||
        this.canApprove(this.leave, this.activeEmployment) ||
        this.isFollower(this.leave, this.activeEmployment) ||
        this.activeCompany.allowEmploymentsToViewAllowanceAccrual
      )
    },
  },

  created() {
    this.setAllowanceSummaryView()
  },

  methods: {
    ...mapActions('auth', ['fetchEmployment']),

    setAllowanceSummaryView() {
      this.isShowAccrued =
        this.activeCompany.allowanceAccrualEnabled &&
        this.activeEmployment.settings.leave_request
          .allowance_deduction_report_type === 'accrued'
    },

    changeSummaryView: debounce(async function() {
      try {
        await Settings.update(this.activeEmployment, {
          company_id: this.activeCompany.id,
          settings: {
            leave_request: {
              allowance_deduction_report_type: this.isShowAccrued
                ? 'accrued'
                : 'yearly',
            },
          },
        })

        this.$emit('summaryViewChanged', this.isShowAccrued)

        await this.fetchEmployment(this.activeCompany)
      } catch (e) {
        this.isShowAccrued = !this.isShowAccrued
      }
    }, 300),
  },
}
</script>

<style lang="scss">
.allowance-stats > {
  div {
    @apply bg-blue-200;

    &:nth-child(1) {
      @apply border-r-0;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &:nth-child(3) {
      @apply border-l-0;
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &.danger {
      @apply border-red-250;
      @apply bg-red-200;
    }
  }
}

.allowance-stats {
  &.danger-current {
    div {
      &:nth-child(2) {
        border-left-color: theme('colors.red.250');
      }
    }
  }

  &.danger-change {
    div {
      &:nth-child(2) {
        border-right-color: theme('colors.red.250');
      }
    }
  }
}
</style>
