var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-border tw-border-blue-350 tw-justify-start tw-p-4 tw-flex tw-items-center",class:{ danger: _vm.allowances.hasRemainingAllowancesExceeded }},[_c('div',{staticClass:"tw-h-full tw-flex tw-flex-col tw-justify-between tw-text-base tw-text-gray-800"},[_c('div',{staticClass:"tw-font-medium tw-flex",class:[
        _vm.allowances.hasRemainingAllowancesExceeded
          ? 'tw-text-red-800'
          : 'tw-text-gray-555',
      ]},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.allowances.hasNewAllowanceRange)?[_vm._v("  Range ")]:_vm._e(),_vm._t("extended-title")],2),_c('div',{staticClass:"tw-mt-2",class:{
        'tw-text-red-700': _vm.allowances.hasRemainingAllowancesExceeded,
      },attrs:{"data-cy":"new-allowance-range"}},[_c('span',{staticClass:"tw-font-semibold tw-text-xl tw-text-gray-880"},[(_vm.allowances.hasUnlimitedAllowances)?[_vm._v("∞")]:[_vm._v(_vm._s(_vm._f("toFraction")(_vm.minimumBalanceAllowance)))]],2),(_vm.allowances.hasNewAllowanceRange)?_c('span',[_c('span',[_vm._v(" to")]),_c('span',{staticClass:"tw-font-semibold tw-text-xl tw-text-gray-880"},[_vm._v(" "+_vm._s(_vm._f("toFraction")(_vm.maximumBalanceAllowance))+" ")])]):_vm._e(),(!_vm.allowances.hasNewAllowanceRange)?[_vm._v(" "+_vm._s(_vm._f("pluralize")(_vm.capitalizedAllowanceUnit,_vm.minimumBalanceAllowance))+" ")]:_vm._e(),(_vm.allowances.hasNewAllowanceRange)?[_vm._v(" "+_vm._s(_vm._f("pluralize")(_vm.capitalizedAllowanceUnit,_vm.maximumBalanceAllowance))+" ")]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }