import moment from 'moment/moment'
import { uniqBy } from 'lodash-es'

export default class DayOverlap {
  constructor(overlap) {
    this.date = moment(overlap.date)
    this.leaves = overlap.leaves
    this.nonVisibleLeaveCount = overlap.nonvisible_leave_count ?? 0
    this.nonvisibleEmploymentCount = overlap.nonvisible_employment_count ?? 0
    this.leaveLimitScope = overlap.leave_limit_scope
    this.canRequestLeaveWhenLimitExceeds =
      overlap.can_request_leave_when_limit_exceeds
  }

  isLeaveLimitExceeded() {
    return this.leaveLimitScope !== null
  }

  leaveOwners() {
    return uniqBy(
      this.leaves.map(leave => leave.owner),
      'id'
    )
  }

  hasOverlapLeave() {
    return this.leaves.length > 0
  }

  hasNonvisibleEmployments() {
    return this.nonVisibleLeaveCount > 0
  }
}
