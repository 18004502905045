import { sumBy } from 'lodash-es'
import Employment from '@/models/employment/Employment'
import Breakdown from '@/models/leave/drafting/breakdowns/Breakdown'

export default class LeaveRequest {
  constructor({ breakdowns, messages, ...employment }) {
    this.messages = messages

    this._setOwner(employment)
    this._setBreakdowns(breakdowns)
  }

  _setOwner(employment) {
    this.owner = new Employment(employment)
    this.owner_id = employment.id
  }

  _setBreakdowns(breakdowns) {
    this.breakdowns = breakdowns.map(breakdown => new Breakdown(breakdown))
  }

  getOwnerKey() {
    return this.owner.getKey()
  }

  getBreakdownsToRequest() {
    return {
      owner_id: this.owner.getKey(),
      breakdowns: this.breakdowns.map(breakdown =>
        breakdown.getBreakdownToRequest()
      ),
    }
  }

  findBreakdownForDate(date) {
    return this.breakdowns.find(breakdown => breakdown.isSameDate(date))
  }

  getCurrentAllowance(remainingAllowance) {
    return remainingAllowance
  }

  getNewAllowance(remainingAllowance) {
    return remainingAllowance - this.getChangedAllowance()
  }

  getChangedAllowance() {
    let divider = this.owner.allowanceUnitIsDays
      ? this.owner.minutesPerWorkingDay
      : 60

    return sumBy(this.breakdowns, 'activeDuration.durationInMinutes') / divider
  }

  get allowanceUnit() {
    return this.owner.allowanceUnit
  }

  get allowanceUnitIsDays() {
    return this.owner.allowanceUnitIsDays
  }

  get minutesPerWorkingDay() {
    return this.owner.minutesPerWorkingDay
  }
}
