<template>
  <div class="tw-space-y-2">
    <div class="tw-flex tw-items-center tw-justify-end">
      <div class="tw-flex tw-items-center tw-space-x-3">
        <BaseButton theme="white" @click="$emit('cancel')">Cancel</BaseButton>

        <SpinnerButtonWithTooltip
          :disabled="submitButtonDisabled"
          :loading="submitButtonLoading"
          :spinner-only="true"
          :button-text="'Send Request'"
          :data-cy="'request-leave'"
          @click="$emit('send-request')"
        >
          <div class="tw-p-4 tw-w-48">
            {{ spinnerButtonTooltipMessage }}
          </div>
        </SpinnerButtonWithTooltip>
      </div>
    </div>

    <div class="tw-text-right tw-text-sm tw-text-gray-555">
      <!-- eslint-disable vue/no-v-html -->
      <div data-cy="micro-copy-text" v-html="microcopyText" />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'
import SpinnerButtonWithTooltip from '@/components/SpinnerButtonWithTooltip.vue'

export default {
  name: 'RequestPanelFooter',

  components: { SpinnerButtonWithTooltip, BaseButton },

  props: {
    submitButtonDisabled: {
      type: Boolean,
      default: false,
    },

    submitButtonLoading: {
      type: Boolean,
      default: false,
    },

    microcopyText: {
      type: String,
      required: true,
    },

    spinnerButtonTooltipMessage: {
      type: String,
      default: '',
    },
  },
}
</script>
