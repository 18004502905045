import WorkingSchedule from '@/components/working-schedule/WorkingSchedule'

export default class Company {
  constructor(rawCompany) {
    this.active_working_schedule = rawCompany.active_working_schedule
    this.allow_calendar_align_to_start_date =
      rawCompany.allow_calendar_align_to_start_date
    this.allow_employees_to_request_overtime =
      rawCompany.allow_employees_to_request_overtime
    this.allowance_unit_is_days = rawCompany.allowance_unit_is_days
    this.calendar_settings = rawCompany.calendar_settings
    this.calendar_start = rawCompany.calendar_start
    this.calendars = rawCompany.calendars
    this.current_billing_plan = rawCompany.current_billing_plan
    this.current_subscription = rawCompany.current_subscription
    this.current_time_off_in_lieu = rawCompany.current_time_off_in_lieu
    this.default_leave_type = rawCompany.default_leave_type
    this.domain_name = rawCompany.domain_name
    this.employments_count = rawCompany.employments_count
    this.first_day_of_week = rawCompany.first_day_of_week
    this.holiday_settings = rawCompany.holiday_settings
    this.id = rawCompany.id
    this.last_subscription = rawCompany.last_subscription
    this.manually_billed = rawCompany.manually_billed
    this.minutes_per_working_day = rawCompany.minutes_per_working_day
    this.name = rawCompany.name
    this.owner_id = rawCompany.owner_id
    this.sso_enforced = rawCompany.sso_enforced
    this.stripe_id = rawCompany.stripe_id
    this.time_off_in_lieu_visibility_settings =
      rawCompany.time_off_in_lieu_visibility_settings
    this.total_employments = rawCompany.total_employments
    this.trial_ends_at = rawCompany.trial_ends_at
    this.updated_at = rawCompany.updated_at
    this.allowance_accrual_enabled = rawCompany.allowance_accrual_enabled
    this.allow_employments_to_view_allowance_accrual =
      rawCompany.allow_employments_to_view_allowance_accrual
    this.billing_plan = rawCompany.billing_plan
  }

  getKey() {
    return this.id
  }

  get minutesPerWorkingDay() {
    return this.minutes_per_working_day
  }

  get allowanceUnitIsDays() {
    return this.allowance_unit_is_days
  }

  get activeWorkingSchedule() {
    if (this.hasOwnProperty('active_working_schedule')) {
      return new WorkingSchedule(this.active_working_schedule)
    }

    return null
  }

  get toilTitle() {
    return (
      this.time_off_in_lieu_visibility_settings?.title ||
      'Earned Time Off In Lieu'
    )
  }

  get hasProperSubscription() {
    return (
      this.employments_count <= 5 ||
      (this.isSubscribed &&
        this.subscription.available_to_use &&
        this.employments_count <= this.numberOfSeats)
    )
  }

  get isSubscribed() {
    return this.subscription !== null
  }

  get subscription() {
    return this.current_subscription
  }

  get numberOfSeats() {
    if (!this.isSubscribed) return 5
    return this.isSubscribed && this.subscription.quantity
  }

  get allowanceAccrualEnabled() {
    return this.allowance_accrual_enabled
  }

  get allowEmploymentsToViewAllowanceAccrual() {
    return this.allow_employments_to_view_allowance_accrual
  }

  hasFeature(feature) {
    return this.billing_plan.hasFeature(feature)
  }
}
