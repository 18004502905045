import LimitedAllowance from '@/models/employment/LimitedAllowance'
import UnlimitedAllowance from '@/models/employment/UnlimitedAllowance'
import LeaveAllowanceSummary from '@/models/leave/LeaveAllowanceSummary'

export default class LeaveAllowances {
  constructor(leave, employmentAllowances) {
    this.employmentAllowances = employmentAllowances
    this.leave = leave
  }

  getAllowances() {
    return this._makeAllowances()
  }

  getAccruedAllowances() {
    return this._makeAllowances('accrual')
  }

  getSummary() {
    return new LeaveAllowanceSummary(this._makeAllowances())
  }

  getAccruedSummary() {
    return new LeaveAllowanceSummary(this._makeAllowances('accrual'))
  }

  _makeAllowances(allowanceSummary = 'yearly') {
    return this.leave.map(leave => {
      let employmentAllowance = this.employmentAllowances
        .all()
        .find(allowance => allowance.employment().id === leave.getOwnerKey())

      return this._makeAllowance(leave, employmentAllowance, allowanceSummary)
    })
  }

  _makeAllowance(leave, allowance = null, allowanceSummary = 'yearly') {
    if (!allowance) {
      return new UnlimitedAllowance(leave)
    }

    const amount =
      allowanceSummary === 'accrual'
        ? allowance.accruedRemainingToBook()
        : allowance.remainingToBook()

    return new LimitedAllowance(leave, amount)
  }
}
