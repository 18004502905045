<template>
  <div
    class="tw-border tw-border-blue-350 tw-justify-start tw-p-4 tw-flex tw-items-center"
    :class="{ danger: allowances.hasRemainingAllowancesExceeded }"
  >
    <div
      class="tw-h-full tw-flex tw-flex-col tw-justify-between tw-text-base tw-text-gray-800"
    >
      <div
        class="tw-font-medium tw-flex"
        :class="[
          allowances.hasRemainingAllowancesExceeded
            ? 'tw-text-red-800'
            : 'tw-text-gray-555',
        ]"
      >
        <span>{{ title }}</span>

        <template v-if="allowances.hasNewAllowanceRange">
          &nbsp;Range
        </template>

        <slot name="extended-title"></slot>
      </div>

      <div
        class="tw-mt-2"
        :class="{
          'tw-text-red-700': allowances.hasRemainingAllowancesExceeded,
        }"
        data-cy="new-allowance-range"
      >
        <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
          <template v-if="allowances.hasUnlimitedAllowances">∞</template>

          <template v-else>{{ minimumBalanceAllowance | toFraction }}</template>
        </span>

        <span v-if="allowances.hasNewAllowanceRange">
          <span> to</span>

          <span class="tw-font-semibold tw-text-xl tw-text-gray-880">
            {{ maximumBalanceAllowance | toFraction }}
          </span>
        </span>

        <template v-if="!allowances.hasNewAllowanceRange">
          {{ capitalizedAllowanceUnit | pluralize(minimumBalanceAllowance) }}
        </template>

        <template v-if="allowances.hasNewAllowanceRange">
          {{ capitalizedAllowanceUnit | pluralize(maximumBalanceAllowance) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FormatNumbers from '@/mixins/FormatNumbers'
import { capitalize } from 'lodash-es'

export default {
  name: 'NewAllowance',

  mixins: [FormatNumbers],

  props: {
    allowances: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    minimumBalanceAllowance() {
      return this.toFixed(this.allowances.minimumBalanceAllowance, 2)
    },

    maximumBalanceAllowance() {
      return this.toFixed(this.allowances.maximumBalanceAllowance, 2)
    },

    capitalizedAllowanceUnit() {
      return capitalize(this.allowances.allowanceUnit)
    },
  },
}
</script>
