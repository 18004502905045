<template>
  <div>
    <div
      v-if="isLeaveLimitReached"
      ref="leaveLimitSummaryWarningContainer"
      class="tw-w-full"
      @mouseover="showOverlapLeaveTooltip"
    >
      <BaseAlert
        class="tw-w-full tw-flex tw-mt-3"
        :theme="cannotRequestLeaveWhenLimitExceeds ? 'danger' : 'warning'"
      >
        Exceeds number of people allowed on leave.
        <template v-slot:right-content>
          <div class="tw-flex tw-justify-end">
            <UserAvatarList
              :nonvisible-user-count="nonvisibleEmploymentCount"
              :users="overlapLeaveOwners"
            />
          </div>
        </template>
      </BaseAlert>
    </div>

    <div v-if="hasOverlapLeave" ref="overlapLeavePanel" class="tw-hidden">
      <div
        class="tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660  tw-bg-gray-660 leave-container"
      >
        <OverlapLeave
          :overlap-leaves="overlapLeaves"
          @show-overlap-leave-details="showLeave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Overlaps from '@/api/reporting/Overlaps'
import BaseAlert from '@/components/BaseAlert'
import UserAvatarList from '@/components/UserAvatarList'
import { uniqBy } from 'lodash-es'
import OverlapLeave from '@/components/requests/leave/OverlapLeave'
import tippy from 'tippy.js'
import Leave from '@/models/leave/Leave'

let overlapLeaveTooltip = null

export default {
  name: 'LeaveLimitSummaryWarning',
  components: {
    BaseAlert,
    UserAvatarList,
    OverlapLeave,
  },

  props: {
    leave: {
      type: Leave,
      required: true,
    },
  },

  data() {
    return {
      leaveLimitExceededOverlaps: [],
    }
  },

  computed: {
    hasOverlapLeave() {
      return this.overlapLeaves.length > 0
    },

    overlapLeaves() {
      return uniqBy(
        this.leaveLimitExceededOverlaps.flatMap(overlap => overlap.leaves),
        'id'
      )
    },

    nonvisibleEmploymentCount() {
      return this.leaveLimitExceededOverlaps.reduce(
        (sum, item) => sum + item.nonvisibleEmploymentCount,
        0
      )
    },

    isLeaveLimitReached() {
      return this.leaveLimitExceededOverlaps.length > 0
    },

    cannotRequestLeaveWhenLimitExceeds() {
      return this.leaveLimitExceededOverlaps.some(
        overlap => overlap.canRequestLeaveWhenLimitExceeds === false
      )
    },

    overlapLeaveOwners() {
      return uniqBy(
        this.overlapLeaves.map(leave => leave.owner),
        'id'
      )
    },
  },

  created() {
    this.fetchLeaveLimitExceededOverlaps()
  },

  methods: {
    showLeave(leaveKey) {
      this.destroyTooltip()

      this.$emit('show-overlap-leave-details', leaveKey)
    },

    async fetchLeaveLimitExceededOverlaps() {
      const from = this.formatToISOString(this.leave.from, this.leave.timezone)
      const to = this.formatToISOString(this.leave.to, this.leave.timezone)

      let overlapLeaves = await Overlaps.get(this.leave.getOwnerKey(), {
        company_id: this.activeCompany.id,
        period: `${from}, ${to}`,
      })

      this.leaveLimitExceededOverlaps = overlapLeaves.getLeaveLimitExceededOverlaps()
    },

    formatToISOString(datetime, timezone) {
      return moment
        .tz(datetime, timezone)
        .utc(true)
        .toISOString()
    },

    showOverlapLeaveTooltip() {
      if (!overlapLeaveTooltip && this.hasOverlapLeave) {
        overlapLeaveTooltip = tippy.one(
          this.$refs.leaveLimitSummaryWarningContainer,
          {
            html: this.$refs.overlapLeavePanel.firstChild,
            theme: 'dark',
            arrow: true,
            interactive: true,
            placement: 'top',
            delay: [400, 0],
            distance: 1,
            interactiveBorder: 15,
          }
        )
      }

      this.$once('hook:beforeDestroy', this.destroyTooltip)
    },

    destroyTooltip() {
      overlapLeaveTooltip?.destroy()
      overlapLeaveTooltip = null
    },
  },
}
</script>
