import moment from 'moment-timezone'

export default class Error {
  constructor(error) {
    this.error = this._removeDraftedLeaveFromLeaveLimitReachedError(error)
  }

  get theme() {
    return 'danger'
  }

  get isOverlapWithExistingLeave() {
    return this.error.code === 'overlap_with_existing_leave'
  }

  get isLeaveLimitReached() {
    return this.error.code === 'leave_limit_reached'
  }

  get text() {
    if (this.isOverlapWithExistingLeave) {
      return this.overlappingLeaveErrorMessage
    }

    if (this.isLeaveLimitReached) {
      return 'Exceeds number of people allowed on leave.'
    }

    return ''
  }

  get overlappingLeaveErrorMessage() {
    const dateFormat = 'D MMM'

    const durationStartTime = this._getMinimumDurationStartTime()
    const durationEndTime = this._getMaximumDurationEndTime()

    const leaveDuration = durationStartTime.isSame(durationEndTime, 'date')
      ? `${durationStartTime.format(dateFormat)}`
      : `${durationStartTime.format(dateFormat)} to ${durationEndTime.format(
          dateFormat
        )}`

    return `Overlaps with existing leave of ${leaveDuration}.`
  }

  get hasOwners() {
    return this.owners.length
  }

  get owners() {
    return this.error.leaves
      .filter(leave => leave.owner)
      .map(leave => leave.owner)
  }

  get leaves() {
    return this.error.leaves
  }

  _getMinimumDurationStartTime() {
    const durationStartTimes = this._getDurationStartTimes()

    return this._sortDurations(durationStartTimes, 'ASC')[0]
  }

  _getDurationStartTimes() {
    return this.error.leaves.map(leave =>
      moment.utc(leave.from).tz(leave.timezone)
    )
  }

  _getMaximumDurationEndTime() {
    const durationEndTimes = this._getDurationEndTimes()

    return this._sortDurations(durationEndTimes, 'DESC')[0]
  }

  _getDurationEndTimes() {
    return this.error.leaves.map(leave =>
      moment.utc(leave.to).tz(leave.timezone)
    )
  }

  _sortDurations(durations, order) {
    return durations.sort((first, second) =>
      order === 'ASC' ? first.diff(second) : second.diff(first)
    )
  }

  _removeDraftedLeaveFromLeaveLimitReachedError(error) {
    if (error.code !== 'leave_limit_reached') {
      return error
    }

    const nonDraftedLeave = error.leaves.filter(
      leave => leave.status !== 'drafted'
    )

    return {
      ...error,
      leaves: nonDraftedLeave,
    }
  }
}
